/* stylelint-disable */
/* stylelint-disable */
/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 20px;
  height: 16px;
  right: 20px;
  top: 17px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373A47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 36px !important;
  width: 36px !important;
  right: 16px !important;
  top: 16px !important;
  padding: 12px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #FFFFFF;
}

/* General sidebar styles */
.bm-menu {
  background: #F52F3D;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-menu .form-group-checkbox {
  margin-top: 15px;
}

.bm-menu-wrap {
  top: 0;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373A47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #FFFFFF;
  padding: 0.8em;
}

.bm-item-list a {
  color: #FFFFFF;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
